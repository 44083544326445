import gsap from 'gsap'
import Splitting from 'splitting'

export default class App {
  constructor() {
    this.getElems()
    this.events()

    gsap.set(this.title, {
      yPercent: 120,
      opacity: 0
    })

    gsap.set([this.$contacts, this.$logo], {
      opacity: 0
    })

    gsap.set(this.$footerEls, { opacity: 0 })

    this.appear()
  }

  getElems() {
    this.$title = document.querySelector('.title')
    this.$logo = document.querySelectorAll('.logo')
    this.$overlay = document.querySelector('.overlay')
    this.$popupBtn = document.querySelector('.popup-btn')
    this.$popup = document.querySelector('.popup')
    this.$popupClose = document.querySelector('.popup-close')
    this.$contacts = document.querySelectorAll('.contact')
    this.$footerEls = document.querySelectorAll('.footer-el')

    this.title = new Splitting({
      target: this.$title,
      by: 'lines'
    })[0].lines
  }

  events() {
    this.$popupBtn.addEventListener('click', this.open.bind(this))
    this.$popupClose.addEventListener('click', this.close.bind(this))
    this.$overlay.addEventListener('click', this.close.bind(this))
    window.addEventListener('keydown', this.onKey.bind(this))
  }

  appear() {
    const tl = gsap.timeline({
      delay: 0.2
    })

    tl.to(this.$logo, {
      opacity: 1,
      ease: 'power2.out',
      duration: 0.9,
      stagger: 0.03
    }, 0.5)

    tl.to(this.$iconPaths, {
      opacity: 1,
      ease: 'power2.out',
      duration: 1
    }, '<30%')

    for (let i = 0; i < this.title.length; i++) {
      tl.to(this.title[i], {
        yPercent: 0,
        opacity: 1,
        duration: 1.2,
        ease: 'expo.out'
      }, 0.5 + 0.08 * i)      
    }

    tl
      .to(this.$contacts, {
        opacity: 1,
        ease: 'expo.out',
        duration: 1.2,
        stagger: 0.08
      }, 0.5 + 0.08 * this.title.length + 0.1)
      .to(this.$footerEls, {
        opacity: 1,
        ease: 'power2.out',
        duration: 0.8,
        stagger: 0.1
      }, '<20%')
  }

  onKey(e) {
    if (e.key === 'Escape') this.close()
  }
  
  open() {
    this.isOpen = true

    this.$overlay.classList.remove('pointer-events-none')
    this.$popup.classList.remove('pointer-events-none')

    const tl = gsap.timeline()

    tl
      .to(this.$overlay, {
        opacity: 0.9,
        ease: 'power3.out',
        duration: 0.5
      }, 0)
      .to(this.$popup, {
        opacity: 1,
        ease: 'power3.out',
        duration: 0.5
      }, 0)
  }

  close() {
    if (!this.isOpen) return

    this.$overlay.classList.add('pointer-events-none')
    this.$popup.classList.add('pointer-events-none')

    const tl = gsap.timeline()

    tl
      .to(this.$overlay, {
        opacity: 0,
        ease: 'power3.out',
        duration: 0.3
      }, 0)
      .to(this.$popup, {
        opacity: 0,
        ease: 'power3.out',
        duration: 0.3
      }, 0)
  }
}

new App()
